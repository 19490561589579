var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fullHeight"},[_c('div',{staticClass:"pa-1",staticStyle:{"height":"90vh","border":"1px solid #fff"}},[(!!_vm.data)?_c('zm-tree-org',{attrs:{"data":_vm.data,"disabled":_vm.disaled,"horizontal":_vm.horizontal,"collapsable":_vm.collapsable,"label-style":_vm.style,"node-draggable":false,"only-one-node":_vm.onlyOneNode},on:{"on-expand":_vm.onExpand,"on-node-click":_vm.onNodeClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12 : 0,"width":"250","color":"#fafaff","outline":""}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"3"}},[_c('v-card',{staticClass:"ma-1",attrs:{"width":"40","height":"70"}},[(node.tabel)?_c('v-img',{attrs:{"src":_vm.$store.state.backend_url + 'get-img/' + node.tabel,"alt":"9777","height":"50","aspect-ratio":5 / 4}}):_vm._e(),_vm._v(" "+_vm._s(node.tabel)+" ")],1)],1),_c('v-col',{staticClass:"text-left ma-0 pa-0 mt-1",staticStyle:{"font-size":"14px"},attrs:{"cols":"9"}},[_c('p',{staticClass:"font-weight-black ma-0 caption"},[_vm._v(" "+_vm._s(node.manager)+" ")]),_c('p',{staticClass:"mr-2 my-0 caption d-inline",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(node.code)+" ")]),_c('p',{staticClass:"mr-2 my-0",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(node.label))])])],1),_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{style:('cursor: pointer;'+(node.show_employee ? 'border:1px solid black;' : '')),attrs:{"elevation":hover ? 12 : 0},on:{"click":function($event){node.show_employee = !node.show_employee}}},[_c('v-icon',{staticClass:"mx-1",attrs:{"color":"blue","title":"Please double click"}},[_vm._v("mdi-account-outline")])],1)]}}],null,true)})],1),_c('v-col',{staticClass:"ma-0 pa-0"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticStyle:{"cursor":"pointer"},attrs:{"elevation":hover ? 12 : 0}},[_c('v-icon',{staticClass:"mx-1",attrs:{"color":"blue","title":"Please double click"},on:{"dblclick":function($event){return _vm.view(node)}}},[_vm._v("mdi-information-outline")])],1)]}}],null,true)})],1),_c('v-col',{staticClass:"ma-0 pa-0"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticStyle:{"cursor":"pointer"},attrs:{"elevation":hover ? 12 : 0}},[_c('v-icon',{staticClass:"mx-1",attrs:{"color":"blue","title":"Please double click"},on:{"dblclick":function($event){return _vm.view(node)}}},[_vm._v("mdi-file-document-outline")])],1)]}}],null,true)})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(node.show_employee),expression:"node.show_employee"}],staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"position":"fixed","background-color":"#cde","z-index":"999","width":"300px","border":"1px solid black"}},[_c('v-list',{staticStyle:{"text-align":"left"},attrs:{"dense":""}},[_c('v-list-item-group',{staticStyle:{"background":"#fff","z-index":"999"},attrs:{"color":"primary"}},_vm._l((node.employees),function(item,i){return _c('v-list-item',{key:i,staticClass:"pa-1",attrs:{"to":'users/profile/'+item.id,"target":"_blank"}},[_c('v-list-item-icon',{staticClass:"ma-1"},[_c('v-avatar',{attrs:{"size":"30"}},[_c('img',{attrs:{"src":_vm.$store.state.backend_url + 'get-img/' + item.tabel}})])],1),_c('v-list-item-content',{staticClass:"ma-0 pa-0"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.fullname)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.staff[0].position.name))])],1)],1)}),1)],1)],1)],1)],1)]}}],null,true)})]}},{key:"expand",fn:function(ref){
var node = ref.node;
return [_c('div',{staticStyle:{"color":"#000"}},[_vm._v(_vm._s(node.children.length))])]}}],null,false,3951475362)}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"300","hide-overlay":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("loadingText"))+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","fullscreen":"","persistent":"","overlay":false,"max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.infoDialog),callback:function ($$v) {_vm.infoDialog=$$v},expression:"infoDialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" title "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","icon":"","x-small":""},on:{"click":function($event){_vm.infoDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_vm._v(" Salom ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }